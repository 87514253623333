.footer-caontainer{
    height:750px;
    width: 100%;
    background-color: #f2f2f2;
    box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 5px;
    display: flex;
    font-family: Playfair Display;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    text-transform: uppercase;
    padding: 16px;
    justify-content: space-evenly;
    margin-top: 40px;
    flex-wrap:wrap;
    padding-bottom: 40px;
}

@media only screen 
  and (min-device-width: 200px) 
  and (max-device-width: 446px) 
{
    .footer-caontainer{
        height:900px;

    }
}
@media only screen 
  and (min-width: 767px) 
  and (max-width: 2024px) 
  {
    .footer-caontainer{
        height:400px;

    }
}
.opening-houres{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height:300px ;
}
.contact {
    font-family: calluna;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    letter-spacing: .015em;
    text-transform: uppercase;
    white-space: pre-wrap;

}
.cantact-info{
    color:#707070;
    letter-spacing: .2em;

    font-family: calluna;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    letter-spacing: .015em;
    text-transform: uppercase;
    white-space: pre-wrap;
}
.title{
    text-align:center;
    white-space:pre-wrap;
    font-family: din-condensed-web;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    letter-spacing: .15em;
    line-height: 2.8em;
    text-transform: uppercase;
}
.opening-houres-item{
    color:#707070;
    display: flex;
    letter-spacing: .2em;
    justify-content: center;
    text-align:center;
    flex-direction: row;
    font-family: calluna;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    letter-spacing: .015em;
    text-transform: uppercase;
    white-space: pre-wrap;
}