.img1{
    height: 700px;
    background: url("../../resources/img2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img2{
    height: 700px;
    background: url("../..//resources/img3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img3{
    height: 700px;
    background: url("../../resources/img4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}