.info-container{
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px;
}

.price-table{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.wellcome-conatiner{
    width: 100%;

}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.pic{
    margin-top:40px ;
    background: url("../../resources/img1.png");
    height: 300px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.wellcome-text{
    font-family: calluna;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    letter-spacing: .015em;
    line-height: 2.3em;
    text-transform: none;
    color: #949494;
}
.price-conatiner{
    margin-top: 60px;
    display: flex;
    width: 100%;
    justify-content: center;
}
/* For tablets: */
@media only screen and (min-width: 600px) {
    .content{
        width: 520px;
        justify-content: center;
        display: flex;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .content{
        width: 820px;
        display: flex;
    }
} 

.title2{
    text-align:center;
    white-space:pre-wrap;
    font-family: calluna;
    font-weight: 600;
    font-style: normal;
    /* font-size: 18px; */
    letter-spacing: .15em;
    line-height: 2.8em;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.price-table-content{
    color:#707070;
    text-align:center;
    flex-direction: row;
    font-family: calluna;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    letter-spacing: .015em;
    line-height: 2.3em;
    /* text-transform: uppercase; */
    white-space: pre-wrap;
}